<template>
  <router-view
    class="box-border"
    :class="show ? 'in-page' : 'van-safe-area-bottom'"
  />
  <TabBlock v-if="!isEHome && show" />
</template>

<script setup>
import { isEHome, isWebView } from '@eaphone/env';

import TabBlock from './tab-block.vue';

const show = import.meta.env.WATCHING || !isWebView;
</script>

<script>
export default {};
</script>

<style scoped>
.in-page {
  padding-bottom: calc(env(safe-area-inset-bottom) + 60px);
}
</style>
