html,
body,
#root {
  @apply h-full;
}

html {
  font-size: 14px;
}

body {
  margin: 0;
  background-color: #f7f8fa;
  font-size: 1rem;

  --van-switch-width: calc(2em + 4px);
  --van-toast-background-color: rgba(black, 50%);
  --van-overlay-background-color: var(--van-toast-background-color);
  --van-cell-value-color: var(--van-text-color) !important;
  --van-cell-label-color: var(--van-text-color) !important;
  --van-collapse-item-content-text-color: var(--van-text-color) !important;
  --van-cell-group-inset-padding: theme('margin.2') !important;
  --van-cell-group-inset-radius: theme('borderRadius.DEFAULT') !important;
  --van-cell-group-inset-title-padding: theme('margin.2') theme('margin.2') 0 !important;
  --van-overlay-background: rgba(0, 0, 0, 0.6);
  --van-empty-description-color: var(--van-text-color);

  @media (width <= 360px) {
    --van-cell-group-inset-padding: var(--van-padding-md) 0 !important;
    --van-cell-group-inset-radius: 0 !important;
  }

  @media (prefers-color-scheme: dark) {
    background-color: black;
  }
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;

  th {
    font-weight: normal;
  }
}

a {
  color: inherit;
}

::placeholder {
  color: lightgrey;
}

img {
  -webkit-touch-callout: none;
}

.van {
  &-cell {
    &__left-icon {
      margin-right: var(--van-padding-sm) !important;
    }

    &__right-icon {
      margin-left: var(--van-padding-xs) !important;
    }

    &__title {
      flex-basis: auto !important;

      &:not(:last-child) {
        white-space: nowrap;
      }
    }

    &__value {
      flex-basis: auto !important;
    }
  }

  &-field {
    &__value {
      + .van-cell__right-icon {
        margin-left: 1em;
      }
    }
  }

  &-picker__frame {
    background-color: theme('colors.vant.primary') + '33';
  }

  &-dialog__message--has-title,
  &-grid-item__icon {
    color: var(--van-text-color);
  }

  &-empty__description {
    width: 100%;
    text-align: center;
  }
}
